//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { sysConfigPage, sysConfigDelete } from '@/api/modular/system/configManage'
import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    XCard,
    STable,
    Ellipsis,
    addForm,
    editForm
  },
  data () {
    return {
      // 高級搜索 展開/關閉
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '參數名稱',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '唯一編碼',
          dataIndex: 'code',
          scopedSlots: { customRender: 'code' }
        },
        {
          title: '參數值',
          dataIndex: 'value',
          scopedSlots: { customRender: 'value' }
        },
        {
          title: '所屬分類',
          dataIndex: 'groupCode',
          scopedSlots: { customRender: 'groupCode' }
        },
        {
          title: '備註',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return sysConfigPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      groupCodeDictTypeDropDown: []
    }
  },
  /**
   * 初始化判斷按鈕權限是否擁有，沒有則不現實列
   */
  created () {
    this.sysDictTypeDropDown()
    if (this.hasPerm('sysConfig:edit') || this.hasPerm('sysConfig:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      this.groupCodeDictTypeDropDown = this.$options.filters['dictData']('consts_type')
    },
    sysConfigDelete (record) {
      sysConfigDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleOk () {
      this.$refs.table.refresh()
    }
  }

}
